<template>
  <div>
    <img v-lazy="lazyLogo" class="logoWrapper" />
    <h1>Weiterleitung</h1>
    <p>Scanne diesen Code auf einem anderen Gerät um die Ziel-Adresse einzugeben.</p>
    <div class="d-flex flex-row justify-content-center">
      <div class="d-flex flex-row justify-content-center align-items-center blob"
        style="width: 130px; height: 130px; cursor: pointer;" @click="full = !full"
      >
        <qrcode-vue v-if="!loading"
          :value="qrUrl" level="H" background="transparent" render-as="svg"
        />
        <b-spinner v-if="loading" />
      </div>
      <div class="d-flex flex-col justify-content-center align-items-center">
        <div class="blob" style="width: 40ch;">
          <h2 v-if="!loading">{{code}}</h2>
          <b-spinner v-if="loading" />
        </div>
      </div>
    </div>
    <span style="font-size: small; color: #bbbbbb;">Klicken um QR-Code zu vergrößern</span>
    <div v-if="scanned && !redirecting">
      <b-spinner small />
      <span> Warte auf Weiterleitung</span>
    </div>
    <div v-if="redirecting">
      <b-spinner small />
      <span> Weiterleitung in wenigen Sekunden</span>
    </div>
    <div v-if="full" @click="full = !full" style="cursor: pointer;"
      class="qr-overlay-wrapper d-flex flex-col justify-content-center align-items-center"
    >
      <qrcode-vue class="qr-overlay-content"
        :value="qrUrl" level="H" background="transparent" :size="fullQrHeight" render-as="svg" />
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'QRCode',
  components: {
    QrcodeVue,
  },
  data() {
    return {
      code: '',
      loading: true,
      scanned: false,
      redirecting: false,
      full: false,
      lazyLogo: {
        src: 'logo.png',
        loading: 'logo-min.png',
      },
      polling: null,
    };
  },
  mounted() {
    this.getCode();
    this.polling = setInterval(() => {
      this.getRedirect();
    }, 2000);
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    next() {
      this.$router.push({
        name: 'Redirect',
        params: {
          code: this.code,
        },
      });
    },
    getCode() {
      this.axios.post('redirection/create')
        .then((response) => {
          this.loading = false;
          this.code = response.data;
        })
        .catch(() => {
          this.error('Beim Erstellen des Codes ist ein Fehler aufgetreten');
        });
    },
    getRedirect() {
      this.axios.get(`redirection/get?code=${this.code}`)
        .then((response) => {
          this.scanned = response.data.scanned;
          if (response.data.url) {
            this.redirecting = true;
            const { url } = response.data;
            setTimeout(() => {
              window.location = `${url.startsWith('http') ? '' : 'http://'}${url}`;
            }, 2000);
          }
        }).catch(() => {
          this.error('Beim Abrufen ist ein Fehler aufgetreten');
        });
    },
  },
  computed: {
    fullQrHeight() {
      return (window.innerHeight / 5) * 3.5;
    },
    qrUrl() {
      return `${window.location.origin}/adress?code=${this.code}`;
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.blob {
  padding: 1rem;
  background-color: #f9f7f7;
  border: 2px #f9f7f7 solid;
  border-radius: 0.5rem;
  margin: 0 1rem;
}

.qr-overlay-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
}

.qr-overlay-content {
  background: #ffffff;
  backdrop-filter: blur(10px);
  padding: 5.5rem;
  border-radius: 3rem;
}

</style>
