<template>
  <div>
    <img v-lazy="lazyLogo" class="logoWrapper" />
    <h1>Adresse angeben</h1>
    <p>Bitte gebe die Adresse an zu der weitergeleitet werden soll.</p>
    <div class="d-flex flex-row justify-content-center" v-if="!created">
      <b-input
        v-if="loaded"
        v-model="url"
        :no-wheel="true"
        type="text"
        ref="text"
        autofocus
        placeholder="https://itmr-dev.de"
        style="text-align: center"
      />
      <div v-if="!loaded">
        <span>
          <b-spinner small />
          Code wird überprüft
        </span>
      </div>
    </div>
    <div v-if="created">
      Wird weitergeleitet. Diese Seite kann geschlossen werden.
    </div>
    <b-button
      @click="redirect"
      variant="primary"
      :disabled="!loaded || created"
      style="margin-top: 1rem"
      >Weiterleiten</b-button
    >
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      loaded: false,
      created: false,
      url: '',
      lazyLogo: {
        src: 'logo.png',
        loading: 'logo-min.png',
      },
    };
  },
  created() {
    if (this.$route.query.code) this.getRedirect();
    else this.error('Kein Code angegeben');
  },
  methods: {
    redirect() {
      this.axios.post('redirection/setUrl', {
        url: this.url,
        code: this.$route.query.code,
      })
        .then(() => {
          this.created = true;
        }).catch(() => {
          this.error('Beim Abrufen ist ein Fehler aufgetreten');
        });
    },
    updateCode(val) {
      if (val.length > 7) {
        this.next();
      }
    },
    getRedirect() {
      this.axios.get(`redirection/setScanned?code=${this.$route.query.code}`)
        .then(() => {
          this.loaded = true;
        }).catch(() => {
          this.error('Beim Abrufen ist ein Fehler aufgetreten');
        });
    },
  },
  computed: {
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
